import React, { useState } from "react";
import { Breadcrumbs } from "../AbstractElements";
import {
  Container,
  Modal,
  Button,
  Badge,
  Select,
  Group,
  LoadingOverlay,
} from "@mantine/core";
import { toast } from "sonner";
import api from "../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Card, CardBody, Col, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Edit, Edit2, Eye, Trash2 } from "react-feather";
import moment from "moment";
import { useDisclosure } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";

const Privates = () => {
  const [privates, setPrivates] = useState([]);
  const [selectedPrivate, setSelectedPrivate] = useState();
  const [editModalOpen, editModalHandlers] = useDisclosure(false);
  const [deleteModalOpen, deleteModalHandlers] = useDisclosure(false);
  const [coaches, setCoaches] = useState([]);
  const queryClient = useQueryClient();

  const { isFetching: loading } = useQuery({
    queryKey: ["getCoaches"],
    queryFn: async () => {
      try {
        const response = await api.get("/coach/list");
        setCoaches(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    refetchOnWindowFocus: true,
  });

  const { isFetching } = useQuery({
    queryKey: ["getPrivates"],
    queryFn: async () => {
      try {
        const response = await api.post("/private/list");
        setPrivates(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    refetchOnWindowFocus: true,
  });

  const columns = [
    {
      name: "Student",
      selector: (row) => `${row?.student?.firstName} ${row?.student?.lastName}`,
    },
    {
      name: "Coach",
      selector: (row) => `${row?.coach?.firstName} ${row?.coach?.lastName}`,
    },
    {
      name: "Status",
      selector: (row) =>
        row?.status === "Pending" ? (
          <Badge color="orange">Pending</Badge>
        ) : row?.status === "Confirmed" ? (
          <Badge color="green">Confirmed</Badge>
        ) : (
          <Badge color="red">Cancelled</Badge>
        ),
    },
    {
      name: "Preferred Time",
      selector: (row) => moment(row?.preferredDate).format("DD/MM/YYYY"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <Button
            size="compact-sm"
            variant="outline"
            className="mx-2"
            onClick={() => {
              setSelectedPrivate(row);
              editModalHandlers.open();
            }}
          >
            <Edit size={13} />
          </Button>
          <Button
            size="compact-sm"
            variant="outline"
            color="red"
            className="mx-2"
            onClick={() => {
              setSelectedPrivate(row);
              deleteModalHandlers.open();
            }}
          >
            <Trash2 size={13} />
          </Button>
        </div>
      ),
    },
  ];

  const editPrivate = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(
          `/private/update/${selectedPrivate?._id}`,
          data
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getPrivates"] });
      toast.success("Private course edited successully");
      setSelectedPrivate(null);
      editModalHandlers.close();
    },
  });

  const handleSubmitEdit = () => {
    editPrivate.mutate(selectedPrivate);
  };

  const deletePrivate = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.delete(
          `/private/delete/${selectedPrivate?._id}`,
          data
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getPrivates"] });
      toast.success("Private course deleted successully");
      setSelectedPrivate(null);
      deleteModalHandlers.close();
    },
  });

  const handleSubmitDelete = () => {
    deletePrivate.mutate(selectedPrivate);
  };

  return (
    <>
      <Breadcrumbs mainTitle="Manage private classes" title="Private classes" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  progressPending={isFetching}
                  pagination
                  columns={columns}
                  data={privates}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        centered
        opened={editModalOpen}
        onClose={editModalHandlers.close}
        title="Edit private session"
      >
        <LoadingOverlay visible={editPrivate.isPending} />
        <Select
          label="Coach"
          data={coaches.map((coach) => {
            return {
              value: coach._id,
              label: `${coach.firstName} ${coach.lastName}`,
            };
          })}
          value={selectedPrivate?.coach?._id}
          onChange={(value) =>
            setSelectedPrivate({ ...selectedPrivate, coach: value })
          }
        />
        <Select
          label="Status"
          data={["Pending", "Confirmed", "Cancelled"]}
          value={selectedPrivate?.status}
          onChange={(value) =>
            setSelectedPrivate({ ...selectedPrivate, status: value })
          }
        />
        <DatePickerInput
          label="Preferred date"
          value={moment(selectedPrivate?.preferredDate)}
          onChange={(value) =>
            setSelectedPrivate({ ...selectedPrivate, preferredDate: value })
          }
          placeholder="Pick a date"
        />

        <Group justify="flex-end" mt="md">
          <Button variant="outline" onClick={editModalHandlers.close}>
            Cancel
          </Button>
          <Button onClick={handleSubmitEdit}>Save</Button>
        </Group>
      </Modal>
      <Modal opened={deleteModalOpen} onClose={deleteModalHandlers.close}>
        Are you sure you want to delete this private session? <br /> This action
        cannot be undone.
        <Group justify="flex-end" mt="md">
          <Button variant="outline" onClick={deleteModalHandlers.close}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={handleSubmitDelete}
            loading={deletePrivate.isPending}
          >
            Delete
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Privates;
