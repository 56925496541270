import {
  IconBulb,
  IconCircleArrowUp,
  IconDashboard,
  IconPodium,
} from "@tabler/icons-react";
import {
  Box,
  Calendar,
  DollarSign,
  Edit,
  File,
  GitCommit,
  HelpCircle,
  Star,
  User,
  Users,
} from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "Activity",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Dashboard",
        icon: IconDashboard,
        type: "link",
        active: false,
        path: "/dashboard",
        id: "dashboard",
      },
      {
        title: "Manage classes",
        icon: Edit,
        type: "link",
        active: false,
        path: "/classes",
        id: "classes",
      },
      {
        title: "Manage Posts",
        icon: GitCommit,
        type: "link",
        active: false,
        path: "/posts",
        id: "posts",
      },
      {
        title: "Manage suggestions",
        icon: IconBulb,
        type: "link",
        active: false,
        path: "/suggestions",
        id: "suggestions",
      },
    ],
  },
  {
    menutitle: "Students",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Manage students",
        icon: Users,
        type: "link",
        active: false,
        path: "/students",
        id: "students",
      },
      {
        title: "Manage memberships",
        icon: DollarSign,
        type: "link",
        active: false,
        path: "/memberships",
        id: "memberships",
      },
    ],
  },
  {
    menutitle: "Schedule",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Manage schedule",
        icon: Calendar,
        type: "link",
        active: false,
        path: "/schedule",
        id: "schedule",
      },
      {
        title: "Manage privates",
        icon: IconCircleArrowUp,
        type: "link",
        active: false,
        path: "/privates",
        id: "privates",
      },
    ],
  },
  {
    menutitle: "Settings",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Manage administrators",
        icon: Calendar,
        type: "link",
        active: false,
        path: "/administrators",
        id: "administrators",
      },
      {
        title: "Manage coaches",
        icon: Star,
        type: "link",
        active: false,
        path: "/coaches",
        id: "coaches",
      },
      {
        title: "Manage membership types",
        icon: Box,
        type: "link",
        active: false,
        path: "/membership-types",
        id: "membership-types",
      },
      {
        title: "Manage FAQ's",
        icon: HelpCircle,
        type: "link",
        active: false,
        path: "/faqs",
        id: "faqs",
      },
      {
        title: "Manage Ranks",
        icon: IconPodium,
        type: "link",
        active: false,
        path: "/ranks",
        id: "ranks",
      },
    ],
  },
];
