import React, { useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { Breadcrumbs, H3, H5, P } from "../AbstractElements";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import { toast } from "sonner";
import {
  Avatar,
  Badge,
  Button,
  Center,
  Drawer,
  Group,
  LoadingOverlay,
  Modal,
  Popover,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import {
  Check,
  CheckCircle,
  Edit2,
  RefreshCcw,
  Users,
  XCircle,
} from "react-feather";
import { useDisclosure } from "@mantine/hooks";
import Belt from "../Components/Belt/Belt";
import "@mantine/tiptap/styles.css";
import { setTimeOnDate, transformDateAndAddHour } from "../utils/functions";

const localizer = momentLocalizer(moment);

const CustomEvent = ({ event }) => (
  <div className="flex flex-col">
    <p className="mb-0 font-bold text-[10px]">{event.title}</p>
    <p className="mb-1 font-light text-[10px]">{event.description}</p>
    <Group justify="space-between" className="mt-0">
      <Badge
        radius={"xl"}
        color="white"
        size="xs"
        leftSection={
          event.additionalData.status === "Finished" ? (
            <Check color="black" size={9} />
          ) : event.additionalData.status === "Cancelled" ? (
            <XCircle color="black" size={9} />
          ) : (
            <RefreshCcw color="black" size={9} />
          )
        }
      >
        <p className="text-gray-900 text-[8px]">
          {event.additionalData.status}
        </p>
      </Badge>
      <Badge
        radius={"xl"}
        size="xs"
        color="white"
        leftSection={<Users color="black" size={11} />}
      >
        <p className="text-gray-900 text-[8px]">
          {event.additionalData.attendedStudents?.length} /{" "}
          {event.additionalData.enrolledStudents?.length}
        </p>
      </Badge>
    </Group>
  </div>
);

const Classes = () => {
  const [classes, setClasses] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [drawerOpen, drawerHandlers] = useDisclosure(false);
  const [editModalOpen, editModalHandlers] = useDisclosure(false);
  const [coaches, setCoaches] = useState([]);

  const queryClient = useQueryClient();

  const { isFetching } = useQuery({
    queryKey: ["getClasses"],
    queryFn: async () => {
      try {
        const response = await api.post("/class/list");
        let results = response.data.map((el) => {
          return {
            title: el.name,
            beginsAt: new Date(el.beginsAt),
            finishesAt: new Date(el.finishesAt),
            description: el.session.type,
            additionalData: el,
          };
        });
        setClasses(results);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const handleClickEvent = (event) => {
    setSelectedEvent(event.additionalData);
    drawerHandlers.open();
  };

  const generateRows = (attendedStudents, enrolledStudents) => {
    const maxLength = Math.max(
      attendedStudents?.length,
      enrolledStudents?.length
    );

    const rows = [];

    for (let i = 0; i < maxLength; i++) {
      const attendedStudent = attendedStudents[i] || null;
      const enrolledStudent = enrolledStudents[i] || null;

      rows.push(
        <Table.Tr key={`${i}`}>
          <Table.Td>
            {attendedStudent && (
              <div className="flex items-center w-full">
                <Avatar
                  src={null}
                  variant="outline"
                  color="black"
                  radius={"lg"}
                />
                <div className="flex flex-col ml-2 w-full">
                  <p className="font-medium text-gray-800">
                    {attendedStudent.firstName} {attendedStudent.lastName}
                  </p>
                  <Belt
                    color={attendedStudent?.rank?.rankInfo?.color}
                    notchColor={
                      attendedStudent?.rank?.rankInfo?.name === "Black belt"
                        ? "red"
                        : "black"
                    }
                  />
                </div>
              </div>
            )}
          </Table.Td>
          <Table.Td>
            {enrolledStudent && (
              <div className="flex items-center w-full">
                <Avatar
                  src={null}
                  variant="outline"
                  color="black"
                  radius={"lg"}
                />
                <div className="flex flex-col ml-2  w-full">
                  <p className="font-medium text-gray-800">
                    {enrolledStudent.firstName} {enrolledStudent.lastName}
                  </p>
                  <Belt
                    color={enrolledStudent?.rank?.rankInfo?.color}
                    notchColor={
                      enrolledStudent?.rank?.rankInfo?.name === "Black belt"
                        ? "red"
                        : "black"
                    }
                  />
                </div>
              </div>
            )}
          </Table.Td>
        </Table.Tr>
      );
    }

    return rows;
  };

  const rows = generateRows(
    selectedEvent?.attendedStudents,
    selectedEvent?.enrolledStudents
  );

  const { isFetching: isLoading } = useQuery({
    queryKey: ["getCoaches"],
    queryFn: async () => {
      try {
        const response = await api.get("/coach/list");
        setCoaches(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const editSession = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`/class/update/${data?._id}`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getClasses"] });
      toast.success("Class edited successully");
      editModalHandlers.close();
      drawerHandlers.close();
    },
  });

  const handleChange = ({ target }) => {
    setSelectedEvent({ ...selectedEvent, [target.name]: target.value });
  };

  const handleSaveEdit = () => {
    // console.log(selectedEvent);
    // console.log(selectedEvent?.beginsAt, selectedEvent?.session?.startTime);
    // console.log(selectedEvent?.finishesAt, selectedEvent?.session?.endTime);
    editSession.mutate({
      ...selectedEvent,
      beginsAt: setTimeOnDate(
        selectedEvent?.beginsAt,
        selectedEvent?.session?.startTime
      ),
      finishesAt: setTimeOnDate(
        selectedEvent?.finishesAt,
        selectedEvent?.session?.endTime
      ),
    });
  };

  const handleCancel = async () => {
    editSession.mutate({ ...selectedEvent, status: "Cancelled" });
    const r = await api.post(`/notifications/class-cancelled`, {
      classId: selectedEvent?._id,
    });
  };

  const handleUnCancel = async () => {
    editSession.mutate({ ...selectedEvent, status: "Upcoming" });
    const r = await api.post(`/notifications/class-un-cancelled`, {
      classId: selectedEvent?._id,
    });
  };

  return (
    <>
      <Breadcrumbs mainTitle="Classes" title="Classes" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <H5>Gym Calendar</H5>
                <span>You can find here this week's and the past classes</span>
              </CardHeader>
              <CardBody>
                <Calendar
                  defaultView="week"
                  showAllEvents={false}
                  localizer={localizer}
                  events={classes}
                  startAccessor="beginsAt"
                  endAccessor="finishesAt"
                  style={{ height: 1000 }}
                  components={{
                    week: { event: CustomEvent },
                    day: { event: CustomEvent },
                  }}
                  views={["month", "week", "day"]}
                  min={new Date(0, 0, 0, 6, 0, 0)}
                  max={new Date(0, 0, 0, 23, 0, 0)}
                  onSelectEvent={handleClickEvent}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Drawer
        opened={drawerOpen}
        title={`${moment(selectedEvent?.beginsAt).format("ddd DD/MM/YY")} - ${
          selectedEvent?.name
        } [${moment(selectedEvent?.beginsAt).format("HH:mm")} - ${moment(
          selectedEvent?.finishesAt
        ).format("HH:mm")}]`}
        size={"lg"}
        withCloseButton
        onClose={() => drawerHandlers.close()}
        position="right"
        styles={{
          title: {
            fontSize: "1.5rem",
            fontWeight: "bold",
          },
        }}
      >
        <h4>{selectedEvent?.session?.type}</h4>
        <Group justify="space-between">
          <h6 className="w-3/4">{selectedEvent?.description}</h6>
          <Badge
            radius={"xl"}
            color={selectedEvent?.status === "Cancelled" ? "red" : "black"}
            leftSection={
              selectedEvent?.status === "Finished" ? (
                <Check size={11} />
              ) : selectedEvent?.status === "Cancelled" ? (
                <XCircle size={11} />
              ) : (
                <RefreshCcw size={11} />
              )
            }
          >
            {selectedEvent?.status}
          </Badge>
        </Group>
        <div className="mt-2">
          <p>
            <strong>Coach :</strong> {selectedEvent?.coach?.firstName}{" "}
            {selectedEvent?.coach?.lastName}
          </p>
        </div>
        <div className="mt-3">
          <p className="flex items-baseline">
            <h3 className="mx-2">{selectedEvent?.attendedStudents?.length}</h3>{" "}
            attended students /{" "}
            <h3 className="mx-2">{selectedEvent?.enrolledStudents?.length}</h3>{" "}
            enrolled students
          </p>
          {!(
            selectedEvent?.attendedStudents.length === 0 &&
            selectedEvent?.enrolledStudents?.length === 0
          ) && (
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Attended students</Table.Th>
                  <Table.Th>Enrolled students</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          )}
        </div>
        {/* <div className="mt-4">
          <h4>Session Recap</h4>
        </div> */}
        <Center>
          {selectedEvent?.status === "Upcoming" && (
            <Popover width={360} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <Button
                  className="m-5"
                  variant="outline"
                  color="red"
                  leftSection={<XCircle size={14} />}
                >
                  Cancel this session
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Text size="sm">
                  Are you sure you want to cancel this session? This will send a
                  notification to all users.
                </Text>
                <Group justify="flex-end">
                  <Button
                    className="mt-2"
                    variant="outline"
                    color="red"
                    onClick={handleCancel}
                  >
                    Confirm cancelling
                  </Button>
                </Group>
              </Popover.Dropdown>
            </Popover>
          )}

          {selectedEvent?.status === "Cancelled" && (
            <Popover width={360} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <Button
                  className="m-5"
                  variant="outline"
                  color="red"
                  leftSection={<XCircle size={14} />}
                >
                  Un-Cancel this session
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Text size="sm">
                  Are you sure you want to un-cancel this session? This will
                  send a notification to all users.
                </Text>
                <Group justify="flex-end">
                  <Button
                    className="mt-2"
                    variant="outline"
                    color="red"
                    onClick={handleUnCancel}
                  >
                    Confirm un-cancelling
                  </Button>
                </Group>
              </Popover.Dropdown>
            </Popover>
          )}

          <Button
            className="m-5"
            variant="outline"
            leftSection={<Edit2 size={14} />}
            onClick={editModalHandlers.open}
          >
            Edit session
          </Button>
          {/* <Button
            className="m-5"
            variant="filled"
            onClick={() => drawerHandlers.close()}
          >
            Save
          </Button> */}
        </Center>
      </Drawer>
      <Modal
        centered
        opened={editModalOpen}
        title="Edit session"
        onClose={editModalHandlers.toggle}
      >
        <LoadingOverlay visible={editSession.isPending} />
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Session name</p>
          <TextInput
            name="name"
            onChange={handleChange}
            value={selectedEvent?.name}
          />
        </Group>
        <div className="mt-2">
          <p className="font-semibold">Session description</p>
          <Textarea
            name="description"
            onChange={handleChange}
            value={selectedEvent?.description}
          />
        </div>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Start time</p>
          <TextInput
            name="startTime"
            onChange={handleChange}
            value={
              selectedEvent?.startTime ??
              transformDateAndAddHour(selectedEvent?.beginsAt)
            }
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">End time</p>
          <TextInput
            name="endTime"
            onChange={handleChange}
            value={
              selectedEvent?.endTime ??
              transformDateAndAddHour(selectedEvent?.finishesAt)
            }
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Session type</p>
          <Select
            data={[
              "NoGi Jiu-Jitsu",
              "Gi Jiu-Jitsu",
              "Wrestling",
              "Kids",
              "White belts",
              "MMA",
            ]}
            onChange={(val) =>
              setSelectedEvent({ ...selectedEvent, type: val })
            }
            value={selectedEvent?.type}
          />
        </Group>
        <Group className="mt-2" justify="space-between">
          <p className="font-semibold">Default coach</p>
          <Select
            data={coaches?.map((coach) => {
              return {
                label: `${coach?.firstName} ${coach?.lastName}`,
                value: coach?._id,
              };
            })}
            onChange={(val) =>
              setSelectedEvent({ ...selectedEvent, coach: val })
            }
            value={selectedEvent?.coach?._id}
          />
        </Group>
        <Group justify="flex-end" className="mt-4">
          <Button
            className="mx-2"
            variant="outline"
            onClick={editModalHandlers.close}
          >
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} variant="filled">
            Save
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Classes;
