import React, { useState } from "react";
import { Col, Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import { Breadcrumbs } from "../AbstractElements";
import DataTable from "react-data-table-component";
import api from "../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import {
  Avatar,
  Badge,
  Button,
  FileButton,
  FileInput,
  Group,
  Input,
  InputLabel,
  LoadingOverlay,
  Modal,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { Edit, RefreshCw, Trash2 } from "react-feather";
import { useDisclosure } from "@mantine/hooks";
import FileBase64 from "react-file-base64";
import { set } from "react-hook-form";
import { host } from "../static";

const Coaches = () => {
  const [coaches, setCoaches] = useState([]);
  const [ranks, setRanks] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [file, setFile] = useState(null);
  const [newCoach, setNewCoach] = useState(null);
  const [newModalOpen, newModalHandlers] = useDisclosure(false);
  const [editModalOpen, editModalHandlers] = useDisclosure(false);
  const [deleteModalOpen, deleteModalHandlers] = useDisclosure(false);

  const queryClient = useQueryClient();

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <div className="flex items-center">
          <Avatar radius="xl" src={`${host}/public/${row?.profilePic}`} />
          <p className="mb-0 ml-2">{`${row?.firstName} ${row?.lastName}`}</p>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) =>
        row.active ? (
          <Badge color={"green"}>Active</Badge>
        ) : (
          <Badge color={"red"}>Inactive</Badge>
        ),
    },
    {
      name: "Role",
      selector: (row) => row.role,
    },
    {
      name: "Rank",
      selector: (row) => (
        <Badge color={row.rank?.color}>{row.rank?.name}</Badge>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <Button
            size="compact-sm"
            variant="outline"
            className="mx-2"
            onClick={() => {
              setSelectedCoach(row);
              editModalHandlers.open();
            }}
          >
            <Edit size={13} />
          </Button>
          <Button
            size="compact-sm"
            variant="outline"
            color="red"
            onClick={() => {
              setSelectedCoach(row);
              deleteModalHandlers.open();
            }}
          >
            {row?.active ? (
              <Trash2 size={13} />
            ) : (
              <RefreshCw size={13} />
            )}
          </Button>
        </div>
      ),
    },
  ];

  const { isFetching } = useQuery({
    queryKey: ["getCoaches"],
    queryFn: async () => {
      try {
        const response = await api.get("/coach/list");
        setCoaches(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    refetchOnWindowFocus: true,
  });

  const { isFetching: loading } = useQuery({
    queryKey: ["getRanks"],
    queryFn: async () => {
      try {
        const response = await api.post("/rank/list");
        setRanks(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    refetchOnWindowFocus: true,
  });

  const handleChangeNew = (e) => {
    setNewCoach({ ...newCoach, [e.target.name]: e.target.value });
  };

  const createCoach = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`/coach/new`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getCoaches"] });
      toast.success("Coach added successully");
      setNewCoach(null);
      newModalHandlers.close();
    },
  });

  const handleSubmitNew = async (file) => {
    createCoach.mutate({ ...newCoach });
  };

  const handleChangeEdit = (e) => {
    setSelectedCoach({ ...selectedCoach, [e.target.name]: e.target.value });
  };

  const editCoach = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(
          `/coach/update/${selectedCoach?._id}`,
          data
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getCoaches"] });
      toast.success("Coach edited successully");
      setSelectedCoach(null);
      editModalHandlers.close();
    },
  });

  const handleSubmitEdit = async (file) => {
    editCoach.mutate({ ...selectedCoach });
  };

  const archiveCoach = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(
          `/coach/archive/${selectedCoach?._id}`,
          data
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getCoaches"] });
      toast.success("Coach archived successully");
      setSelectedCoach(null);
      deleteModalHandlers.close();
    },
  });

  const handleArchive = async () => {
    archiveCoach.mutate({ ...selectedCoach });
  };

  return (
    <>
      <Breadcrumbs mainTitle="Manage coaches" title="Coaches" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="flex justify-end pb-0">
                <Button
                  variant="outline"
                  color="primary"
                  className="mb-2"
                  onClick={newModalHandlers.open}
                >
                  Add Coach
                </Button>
              </CardHeader>
              <CardBody>
                <DataTable
                  progressPending={isFetching}
                  pagination
                  columns={columns}
                  data={coaches}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        opened={newModalOpen}
        title="Add a new coach"
        onClose={newModalHandlers.close}
      >
        <LoadingOverlay visible={createCoach.isPending} />
        <TextInput
          type="text"
          name="firstName"
          placeholder="First name"
          label="First name"
          onChange={handleChangeNew}
          value={newCoach?.firstName}
        />
        <TextInput
          type="text"
          name="lastName"
          placeholder="Last name"
          label="Last name"
          onChange={handleChangeNew}
          value={newCoach?.lastName}
        />
        <TextInput
          type="text"
          name="role"
          placeholder="Role"
          label="Role"
          onChange={handleChangeNew}
          value={newCoach?.role}
        />
        <Select
          label="Rank"
          data={ranks.map((rank) => ({ value: rank._id, label: rank.name }))}
          onChange={(value) => setNewCoach({ ...newCoach, rank: value })}
          value={newCoach?.rank}
        />
        <Group justify="space-between" className="mt-2">
          <InputLabel>Profile picture</InputLabel>
          <FileBase64
            multiple={false}
            onDone={({ base64 }) =>
              setNewCoach({ ...newCoach, profilePic: base64 })
            }
          />
        </Group>
        <Group justify="flex-end" mt="md">
          <Button variant="outline" onClick={newModalHandlers.close}>
            Cancel
          </Button>
          <Button onClick={handleSubmitNew}>Submit</Button>
        </Group>
      </Modal>
      <Modal
        opened={editModalOpen}
        title="Add a new coach"
        onClose={editModalHandlers.close}
      >
        <LoadingOverlay visible={editCoach.isPending} />
        <TextInput
          type="text"
          name="firstName"
          placeholder="First name"
          label="First name"
          onChange={handleChangeEdit}
          value={selectedCoach?.firstName}
        />
        <TextInput
          type="text"
          name="lastName"
          placeholder="Last name"
          label="Last name"
          onChange={handleChangeEdit}
          value={selectedCoach?.lastName}
        />
        <TextInput
          type="text"
          name="role"
          placeholder="Role"
          label="Role"
          onChange={handleChangeEdit}
          value={selectedCoach?.role}
        />
        <Select
          label="Rank"
          data={ranks.map((rank) => ({ value: rank._id, label: rank.name }))}
          onChange={(value) =>
            setSelectedCoach({ ...selectedCoach, rank: value })
          }
          value={selectedCoach?.rank?._id ?? selectedCoach?.rank}
        />
        <Group justify="space-between" className="mt-2">
          <InputLabel>Profile picture</InputLabel>
          <FileBase64
            multiple={false}
            onDone={({ base64 }) =>
              setSelectedCoach({ ...newCoach, profilePic: base64 })
            }
          />
        </Group>
        <Group justify="flex-end" mt="md">
          <Button variant="outline" onClick={editModalHandlers.close}>
            Cancel
          </Button>
          <Button onClick={handleSubmitEdit}>Submit</Button>
        </Group>
      </Modal>
      <Modal opened={deleteModalOpen} onClose={deleteModalHandlers.close}>
        <Text>
          Are you sure you want to{" "}
          {selectedCoach?.active ? "archive" : "unarchive"} this coach?
        </Text>
        <Group justify="flex-end" mt="md">
          <Button variant="outline" onClick={deleteModalHandlers.close}>
            Cancel
          </Button>
          <Button color="red" onClick={handleArchive}>
            {selectedCoach?.active ? "Archive" : "Unarchive"}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Coaches;
