import React, { useState } from "react";
import { Breadcrumbs } from "../AbstractElements";
import { Container, Group, Modal, Button } from "@mantine/core";
import { toast } from "sonner";
import api from "../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Card, CardBody, Col, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Eye, Trash2 } from "react-feather";
import moment from "moment";
import { useDisclosure } from "@mantine/hooks";

const Suggestions = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState();
  const [modalOpen, modalHandlers] = useDisclosure(false);
  const [deleteModalOpen, deleteModalHandlers] = useDisclosure(false);
  const queryClient = useQueryClient();

  const { isFetching } = useQuery({
    queryKey: ["getSuggestions"],
    queryFn: async () => {
      try {
        const response = await api.get("/suggestion/list");
        setSuggestions(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    refetchOnWindowFocus: true,
  });

  const columns = [
    {
      name: "From",
      selector: (row) => `${row?.student?.firstName} ${row?.student?.lastName}`,
    },
    {
      name: "Time",
      selector: (row) => moment(row?.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <Button
            size="compact-sm"
            variant="outline"
            color="primary"
            className="mx-2"
            onClick={() => {
              setSelectedSuggestion(row);
              modalHandlers.open();
            }}
          >
            <Eye size={13} />
          </Button>
          <Button
            size="compact-sm"
            variant="outline"
            color="red"
            className="mx-2"
            onClick={() => {
              setSelectedSuggestion(row);
              deleteModalHandlers.open();
            }}
          >
            <Trash2 size={13} />
          </Button>
        </div>
      ),
    },
  ];

  const deleteSuggestion = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.delete(
          `/suggestion/delete/${selectedSuggestion?._id}`,
          data
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getSuggestions"] });
      toast.success("Suggestion deleted successully");
      setSelectedSuggestion(null);
      deleteModalHandlers.close();
    },
  });

  const handleSubmitDelete = () => {
    deleteSuggestion.mutate(selectedSuggestion);
  };

  return (
    <>
      <Breadcrumbs mainTitle="Manage suggestions" title="Suggestions" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  progressPending={isFetching}
                  pagination
                  columns={columns}
                  data={suggestions}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        centered
        opened={modalOpen}
        onClose={modalHandlers.close}
        title={`Suggestion from ${selectedSuggestion?.student?.firstName} ${selectedSuggestion?.student?.lastName}`}
      >
        <p style={{ fontWeight: "bold" }}>{selectedSuggestion?.subject}</p>
        <p>{selectedSuggestion?.text} </p>
      </Modal>
      <Modal opened={deleteModalOpen} onClose={deleteModalHandlers.close}>
        Are you sure you want to delete this suggestion? <br /> This action
        cannot be undone.
        <Group justify="flex-end" mt="md">
          <Button variant="outline" onClick={deleteModalHandlers.close}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={handleSubmitDelete}
            loading={deleteSuggestion.isPending}
          >
            Delete
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Suggestions;
