import React, { useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import {
  ActionIcon,
  Button,
  Group,
  Card as MantineCard,
  Menu,
  Modal,
  rem,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { Breadcrumbs, H1, H2, H5, P } from "../AbstractElements";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import { toast } from "sonner";
import { Paper, SimpleGrid } from "@mantine/core";
import { Edit, PlusCircle } from "react-feather";
import { IconDots, IconEye, IconFileZip, IconTrash } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { duration } from "moment";

const MembershipTypes = () => {
  const [membershipTypes, setMembershipTypes] = useState([]);
  const [newMTOpened, newMTHandlers] = useDisclosure(false);
  const [editMTOpened, editMTHandlers] = useDisclosure(false);
  const [deleteMTOpened, deleteMTHandlers] = useDisclosure(false);
  const [selectedMT, setSelectedMT] = useState(null);

  const queryClient = useQueryClient();

  const closeMTModal = () => {
    newMTHandlers.close();
    editMTHandlers.close();
    deleteMTHandlers.close();
    setSelectedMT(null);
  };

  const openEditMTModal = (mt) => {
    setSelectedMT(mt);
    editMTHandlers.open();
  };

  const openDeleteModal = (mt) => {
    setSelectedMT(mt);
    deleteMTHandlers.open();
  };

  const { isFetching } = useQuery({
    queryKey: ["getMembershipTypes"],
    queryFn: async () => {
      try {
        const response = await api.post("/membership-type/list");
        setMembershipTypes(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const newMT = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(`membership-type/new`, data);
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getMembershipTypes"] });
      closeMTModal();
      toast.success("Membership Type added successully");
    },
  });

  const editMT = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.post(
          `membership-type/update/${selectedMT?._id}`,
          data
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getMembershipTypes"] });
      closeMTModal();
      toast.success("Membership Type edited successully");
    },
  });

  const deleteMT = useMutation({
    mutationFn: async () => {
      try {
        const response = await api.post(
          `membership-type/delete/${selectedMT?._id}`
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getMembershipTypes"] });
      closeMTModal();
      toast.success("Membership Type deleted successully");
    },
  });

  const handleChange = ({ target }) => {
    setSelectedMT({ ...selectedMT, [target.name]: target.value });
  };

  return (
    <>
      <Breadcrumbs mainTitle="Membership Types" title="Membership types" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Group justify="flex-end">
                  <Button
                    variant="outline"
                    leftSection={<PlusCircle size={15} />}
                    onClick={newMTHandlers.open}
                  >
                    Add Membership Type
                  </Button>
                </Group>
              </CardHeader>
              <CardBody>
                <SimpleGrid cols={4}>
                  {membershipTypes?.map((mt, key) => (
                    <MantineCard
                      key={key}
                      shadow="sm"
                      padding={"sm"}
                      withBorder
                    >
                      <Group justify="flex-end">
                        <Menu withinPortal position="bottom-end" shadow="sm">
                          <Menu.Target>
                            <ActionIcon variant="subtle" color="gray">
                              <IconDots
                                style={{ width: rem(16), height: rem(16) }}
                              />
                            </ActionIcon>
                          </Menu.Target>

                          <Menu.Dropdown>
                            <Menu.Item
                              onClick={() => openEditMTModal(mt)}
                              leftSection={
                                <Edit
                                  style={{ width: rem(14), height: rem(14) }}
                                />
                              }
                            >
                              Edit
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => openDeleteModal(mt)}
                              leftSection={
                                <IconTrash
                                  style={{ width: rem(14), height: rem(14) }}
                                />
                              }
                              color="red"
                            >
                              Delete
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </Group>
                      <H5>{mt?.name}</H5>
                      <P>{mt?.description}</P>
                      <P>
                        {mt?.duration?.number} {mt?.duration?.type}
                      </P>
                      <H1>{mt?.price} DT</H1>
                    </MantineCard>
                  ))}
                </SimpleGrid>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        title="New membership type"
        opened={newMTOpened || editMTOpened}
        onClose={closeMTModal}
      >
        <TextInput
          label="Name"
          placeholder="Name"
          name="name"
          onChange={handleChange}
          value={selectedMT?.name}
        />
        <TextInput
          label="Description"
          placeholder="Description"
          name="description"
          onChange={handleChange}
          value={selectedMT?.description}
        />
        <TextInput
          label="Price"
          placeholder="Price"
          name="price"
          type="number"
          onChange={handleChange}
          value={selectedMT?.price}
        />
        <Group>
          <TextInput
            type="number"
            label="Duration"
            placeholder="Number of ..."
            onChange={(e) => {
              setSelectedMT({
                ...selectedMT,
                duration: { ...selectedMT.duration, number: e.target.value },
              });
            }}
            value={selectedMT?.duration?.number}
          />
          <Select
            label="Type"
            placeholder="Type"
            data={["days", "months", "years"]}
            onChange={(val) =>
              setSelectedMT({
                ...selectedMT,
                duration: { ...selectedMT.duration, type: val },
              })
            }
            value={selectedMT?.duration?.type}
          />
        </Group>
        <Group justify="flex-end" className="mt-5">
          <Button variant="outline" onClick={closeMTModal}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              newMTOpened ? newMT.mutate(selectedMT) : editMT.mutate(selectedMT)
            }
            loading={newMT.isPending || editMT.isPending}
          >
            Save
          </Button>
        </Group>
      </Modal>
      <Modal opened={deleteMTOpened} onClose={deleteMTHandlers.close}>
        <p>Are you sure you want to delete this membership type?</p>
        <Group justify="flex-end" className="mt-5">
          <Button variant="outline" onClick={deleteMTHandlers.close}>
            Cancel
          </Button>
          <Button color="red" onClick={() => deleteMT.mutate()}>
            Delete
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default MembershipTypes;
