import Dashboard from "../Pages/Dashboard";
import Posts from "../Pages/Posts";
import Students from "../Pages/Students";
import Memberships from "../Pages/Memberships";
import Schedule from "../Pages/Schedule";
import Administrators from "../Pages/Administrators";
import MembershipTypes from "../Pages/MembershipTypes";
import Faq from "../Pages/Faq";
import Ranks from "../Pages/Ranks";
import Classes from "../Pages/Classes";
import Coaches from "../Pages/Coaches";
import Suggestions from "../Pages/Suggestions";
import Privates from "../Pages/Privates";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard />},
  { path: `${process.env.PUBLIC_URL}/classes`, Component: <Classes />},
  { path: `${process.env.PUBLIC_URL}/posts`, Component: <Posts />},
  { path: `${process.env.PUBLIC_URL}/students`, Component: <Students />},
  { path: `${process.env.PUBLIC_URL}/memberships`, Component: <Memberships />},
  { path: `${process.env.PUBLIC_URL}/schedule`, Component: <Schedule />},
  { path: `${process.env.PUBLIC_URL}/administrators`, Component: <Administrators />},
  { path: `${process.env.PUBLIC_URL}/membership-types`, Component: <MembershipTypes />},
  { path: `${process.env.PUBLIC_URL}/faqs`, Component: <Faq />},
  { path: `${process.env.PUBLIC_URL}/ranks`, Component: <Ranks />},
  { path: `${process.env.PUBLIC_URL}/coaches`, Component: <Coaches />},
  { path: `${process.env.PUBLIC_URL}/privates`, Component: <Privates />},
  { path: `${process.env.PUBLIC_URL}/suggestions`, Component: <Suggestions />},

];
