import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Badge,
} from "reactstrap";
import { Breadcrumbs, H5, P } from "../AbstractElements";
import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import { toast } from "sonner";
import DataTable from "react-data-table-component";
import { Button, Group, Modal, TextInput } from "@mantine/core";
import { IconInputSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Eye, Trash2 } from "react-feather";
import { useDisclosure } from "@mantine/hooks";

const Dashboard = () => {
  const [memberships, setMemberships] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSuggestion, setSelectedSuggestion] = useState();
  const [modalOpen, modalHandlers] = useDisclosure(false);
  const [deleteModalOpen, deleteModalHandlers] = useDisclosure(false);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { isFetching } = useQuery({
    queryKey: ["getMemberships"],
    queryFn: async () => {
      try {
        const response = await api.post("/membership/list");
        setMemberships(response.data?.filter((el) => el?.status === "Overdue"));
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const { isFetching: isFetchingSuggestions } = useQuery({
    queryKey: ["getSuggestions"],
    queryFn: async () => {
      try {
        const response = await api.get("/suggestion/list");
        setSuggestions(response.data);
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const columns = [
    {
      name: "Student Name",
      selector: (row) => `${row.student?.firstName} ${row.student?.lastName}`,
    },
    {
      name: "Membership Type",
      selector: (row) => (
        <P>
          {row?.membershipType?.duration?.number}{" "}
          {row?.membershipType?.duration?.type}
        </P>
      ),
    },
    {
      name: "Expired at",
      selector: (row) => moment(row.expiryDate).format("DD MMMM YYYY"),
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.expiryDate);
        const dateB = new Date(rowB.expiryDate);

        return dateA - dateB;
      },
    },
  ];
  const filteredMemberships = memberships.filter((membership) => {
    const fullName =
      `${membership?.student?.firstName} ${membership?.student?.lastName}`.toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();

    return fullName.includes(searchTermLower);
  });

  const columnsSuggestions = [
    {
      name: "From",
      selector: (row) => `${row?.student?.firstName} ${row?.student?.lastName}`,
    },
    {
      name: "Time",
      selector: (row) => moment(row?.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <Button
            size="compact-sm"
            variant="outline"
            color="primary"
            className="mx-2"
            onClick={() => {
              setSelectedSuggestion(row);
              modalHandlers.open();
            }}
          >
            <Eye size={13} />
          </Button>
          <Button
            size="compact-sm"
            variant="outline"
            color="red"
            className="mx-2"
            onClick={() => {
              setSelectedSuggestion(row);
              deleteModalHandlers.open();
            }}
          >
            <Trash2 size={13} />
          </Button>
        </div>
      ),
    },
  ];

  const deleteSuggestion = useMutation({
    mutationFn: async (data) => {
      try {
        const response = await api.delete(
          `/suggestion/delete/${selectedSuggestion?._id}`,
          data
        );
        return response.data;
      } catch (error) {
        toast.error("Error", { description: error.message });
        throw new Error(error.message);
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["getSuggestions"] });
      toast.success("Suggestion deleted successully");
      setSelectedSuggestion(null);
      deleteModalHandlers.close();
    },
  });

  const handleSubmitDelete = () => {
    deleteSuggestion.mutate(selectedSuggestion);
  };

  return (
    <>
      <Breadcrumbs mainTitle="Dashboard" title="Dashboard" />
      <Container fluid>
        <Row>
          <Col sm="6">
            <Card>
              <CardHeader>
                <Group justify="space-between">
                  <H5>Overdue Memberships</H5>
                  <Button
                    variant="outline"
                    onClick={() => navigate("/memberships")}
                  >
                    View all memberships
                  </Button>
                </Group>
              </CardHeader>
              <CardBody>
                <TextInput
                  style={{ marginBottom: 8 }}
                  type="search"
                  className="w-full"
                  leftSection={<IconInputSearch />}
                  placeholder="Type to search for a student by name"
                  onChange={handleSearch}
                />
                <DataTable
                  progressPending={isFetching}
                  pagination
                  columns={columns}
                  data={filteredMemberships}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card>
              <CardHeader>
                <Group justify="space-between">
                  <H5>Latest Suggestions</H5>
                  <Button
                    variant="outline"
                    onClick={() => navigate("/suggestions")}
                  >
                    View all suggestions
                  </Button>
                </Group>
              </CardHeader>
              <CardBody>
                <DataTable
                  progressPending={isFetchingSuggestions}
                  pagination
                  columns={columnsSuggestions}
                  data={suggestions}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        centered
        opened={modalOpen}
        onClose={modalHandlers.close}
        title={`Suggestion from ${selectedSuggestion?.student?.firstName} ${selectedSuggestion?.student?.lastName}`}
      >
        <p style={{ fontWeight: "bold" }}>{selectedSuggestion?.subject}</p>
        <p>{selectedSuggestion?.text}</p>
      </Modal>
      <Modal opened={deleteModalOpen} onClose={deleteModalHandlers.close}>
        Are you sure you want to delete this suggestion? <br /> This action
        cannot be undone.
        <Group justify="flex-end" mt="md">
          <Button variant="outline" onClick={deleteModalHandlers.close}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={handleSubmitDelete}
            loading={deleteSuggestion.isPending}
          >
            Delete
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default Dashboard;
